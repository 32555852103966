import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

import { mq } from './styles/Layout.styles'
import Atom from './styles/Atom.styles'

/* Default sizes change if you need */
export const StyledAtomImg = styled(Atom)(props => (
  mq({
    flex: '0 0 auto'
  })
))

const AtomImg = (props) => (
  <StyledAtomImg 
    as={Img}
    {...props}
  />
)

AtomImg.defaultProps = {
  width: ['auto']
}

export default AtomImg
